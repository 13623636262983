import {
    Datagrid,
    List,
    TextField,
    ArrayField,
    DateField,
    Show,
    SimpleShowLayout, ReferenceArrayField, ShowButton
} from 'react-admin';

export const AccountList = () => (
    <List>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="type"/>
            <TextField source="hosts_count"/>
            <TextField source="groups_count"/>
            <ReferenceArrayField source="services_ids" reference="service" />
        </Datagrid>
    </List>
);

export const AccountShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="type"/>
            <ArrayField source="properties">
                <Datagrid>
                    <TextField source="name"/>
                    <TextField source="values"/>
                </Datagrid>
            </ArrayField>
            <ArrayField source="changes">
                <Datagrid>
                    <TextField source="name"/>
                    <TextField source="type"/>
                    <DateField source="changed_at"/>
                </Datagrid>
            </ArrayField>
            <ReferenceArrayField label="Groups" reference="group" source="groups_ids">
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
            <ReferenceArrayField label="Services" reference="service" source="services_ids">
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
            <ReferenceArrayField label="Hosts" reference="host" source="hosts_ids">
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="path" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
        </SimpleShowLayout>
    </Show>
);
