import {
    AutocompleteArrayInput, Create,
    Datagrid, Edit,
    List,
    ReferenceArrayField, ReferenceArrayInput,
    Show,
    ShowButton, SimpleForm,
    SimpleShowLayout,
    TextField, TextInput
} from 'react-admin';

export const ServiceList = () => (
    <List>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <ReferenceArrayField source="groups_ids" reference="group" />
            <TextField source="accounts_count" />
        </Datagrid>
    </List>
);

export const ServiceShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            <ReferenceArrayField label="Groups" reference="group" source="groups_ids">
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
            <ReferenceArrayField label="Accounts" reference="account" source="accounts_ids">
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
        </SimpleShowLayout>
    </Show>
);

export const ServiceEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceArrayInput source="groups_ids" reference="group">
                <AutocompleteArrayInput />
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);

export const ServiceCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceArrayInput source="groups_ids" reference="group">
                <AutocompleteArrayInput />
            </ReferenceArrayInput>
        </SimpleForm>
    </Create>
);
