import React from 'react';
import {Admin, EditGuesser, ListGuesser, Resource, ShowGuesser} from 'react-admin';
import { DashboardDataProvider } from './dataProvider';
import { AccountList, AccountShow } from './models/account';
import {GroupList, GroupShow} from './models/group';
import { HostCreate, HostEdit, HostList, HostShow } from './models/host';
import { ServiceCreate, ServiceEdit, ServiceList, ServiceShow } from './models/service';
import authProvider from './authProvider';

const dataProvider = new DashboardDataProvider('/api');

function App() {
    return (
        <Admin disableTelemetry dataProvider={ dataProvider } authProvider={ authProvider }>
            <Resource name="account" list={ AccountList } show={ AccountShow }/>
            <Resource name="group" list={ GroupList } show={ GroupShow }/>
            <Resource name="service" list={ ServiceList } show={ ServiceShow } edit={ ServiceEdit } create={ ServiceCreate } />
            <Resource name="host" list={ HostList } show={ HostShow } edit={ HostEdit } create={ HostCreate } />
        </Admin>
    );
}

export default App;
